<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet>
          <template v-slot:title>
            {{ $t("timeTable.title") }}
          </template>
          <template v-slot:toolbar>
            <b-form-datepicker 
                id="example-datepicker" 
                v-model="filter.date" class="mb-2" 
                @context="changeDate"
                locale="ru"
                start-weekday="1"
                :date-disabled-fn="dateDisabled"></b-form-datepicker>
          </template>
          <template v-slot:body>
            <b-form>
              <div class="row">
                <div class="col-4">
                  <b-form-group id="input-group-3" label-for="input-3">
                    <template v-slot:label>
                      {{ $t("studentGroups.list.year") }}
                    </template>
                    <b-form-select
                        id="input-3"
                        v-model="filter.educationYear"
                        :options="educationYears"
                        required
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group id="input-group-2" label-for="input-2">
                    <template v-slot:label>
                      {{ $t("studentGroups.list.level") }}
                    </template>
                    <b-form-select
                        id="input-2"
                        v-model="filter.level"
                        :options="levels"
                        required
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group id="input-group-4" label-for="input-4">
                    <template v-slot:label>
                      {{ $t("studentGroups.list.shift") }}
                    </template>
                    <b-form-select
                        id="input-4"
                        v-model="filter.shift"
                        :options="shifts"
                        required
                    ></b-form-select>
                  </b-form-group>
                </div>

              </div>
              <b-button type="button" @click="filterData" variant="primary"
                        :class="{'kt-spinner kt-spinner--light disabled':loading }"
                        :disabled="loading"
              >{{
                  $t("common.filter")
                }}
              </b-button>
            </b-form>
            <div style="text-align: right">
              <b-button type="button" @click="openCopyModal" variant="primary"
                        :class="{'kt-spinner kt-spinner--light disabled':loading }"
                        :disabled="loading"
              >
                Скопировать на неделю
              </b-button>
            </div>
            <div v-for="item in data" :key="item.group.id" style="overflow-x: auto">
              <div class="group-name"><span>{{ item.group.groupName }}</span>
                <button class="btn btn-primary"
                        v-if="showCopyButtonFromPrevWeek(item.group.id)"
                        @click="copyFromPrevWeek(item.group.id)"
                        :class="{'kt-spinner kt-spinner--light disabled':loading }"
                        :disabled="loading"
                >
                  {{ $t("timeTable.copyFromPrevWeek") }}
                </button>
                <button class="btn btn-primary"
                        v-if="showCopyButtonFromPrevWeek(item.group.id)"
                        @click="copyFromNextWeek(item.group.id)"
                        :class="{'kt-spinner kt-spinner--light disabled':loading }"
                        :disabled="loading"
                >
                  {{ $t("timeTable.copyFromNextWeek") }}
                </button>
              </div>
              <table class="table table-bordered">
                <thead class="thead-light">
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">
                    {{ $t("timeTable.day.monday") }}
                    <span class="column-date">
                      {{dates[1]}}
                    </span>
                  </th>
                  <th class="text-left">
                    {{ $t("timeTable.day.tuesday") }}
                    <span class="column-date">
                      {{dates[2]}}
                    </span>
                  </th>
                  <th class="text-left">
                    {{ $t("timeTable.day.wednesday") }}
                    <span class="column-date">
                      {{dates[3]}}
                    </span>
                  </th>
                  <th class="text-left">
                    {{ $t("timeTable.day.thursday") }}
                    <span class="column-date">
                      {{dates[4]}}
                    </span>
                  </th>
                  <th class="text-left">
                    {{ $t("timeTable.day.friday") }}
                    <span class="column-date">
                      {{dates[5]}}
                    </span>
                  </th>
                  <th class="text-left">
                    {{ $t("timeTable.day.saturday") }}
                    <span class="column-date">
                      {{dates[6]}}
                    </span>
                  </th>
                </tr>
                </thead>
                <tbody :reDrawTable="reDrawTable">
                <tr v-for="dayItem in dayItems" :key="dayItem">
                  <td>{{ dayItem }}</td>
                  <td v-for="day in days" :key="day"
                      v-on:drop="drop"
                      v-on:dragover="allowDrop"
                      :group-id="item.group.id"
                      :day="day"
                      :day-item="dayItem"
                  >
                    <button class="btn btn-outline-primary" v-if="form[item.group.id][day][dayItem].length == 0"
                            @click="clickItem(item, day, dayItem, item.subjects, item.subjectsPerWeek)">
                      <i class="fa fa-plus"></i> {{ $t("common.add") }}
                    </button>
                    <div v-for="(item1, k) in form[item.group.id][day][dayItem]" :key="k"
                         :draggable="true"
                         :group-id="item.group.id"
                         :day="day"
                         :day-item="dayItem"
                         :id="item.group.id + '_' + day + '_'+ dayItem + '_'+ k"
                         :index="k"
                         :is-additional="item1.isAdditional"
                         :class="{'additional-lesson':item1.isAdditional }"
                         @dragstart="dragStart($event)"
                         class="time-table-item rounded-lg">
                      <div>
                        <button @click.self="removeItem(item.group.id, day, dayItem, k)"
                                class="btn btn-danger">Удалить</button>
                        <br>
                        <button @click.self="editItem(item.group.id, day, dayItem, k)"
                                class="btn btn-danger">Переместить</button>
                      </div>

                      {{ subjectNames[item1.subjectId] }} <i v-if="item1.isAdditional=== true">(Доп)</i>
                      <div>Учитель {{item1.teacherName}}</div>
                      <div>Кабинет {{item1.roomNumber}}</div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div>
              <b-button type="submit" @click="onSubmit" variant="primary" id="submitButton"
                        :class="{'kt-spinner kt-spinner--light disabled':loading }"
                        :disabled="loading"
              >
                {{ $t("common.save") }}
              </b-button>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>

    <div>
      <b-modal id="createItem" ref="createItem-modal" @hide="forceReDraw">
        <template v-slot:modal-title>
          {{ $t("common.new") }}
        </template>
        <div style="height: 400px; overflow-y: auto">
          <table :currentDay="currentDay" :currentDayItem="currentDayItem" :reDrawTable="reDrawTable"
                 v-if="form[currentGroup]"
                 class="table table-bordered">
            <thead>
            <tr>
              <th>Предмет</th>
              <th>{{ $t("timeTable.roomNumber") }}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, k) in getSubjectsPerWeek()" :key="k">
              <td :saa="item">
                {{item.text}}
              </td>
              <td>
                {{item.roomNumber}}
              </td>
              <td>
                <button class="btn btn-icon btn-danger"
                        @click="pickItem(currentGroup, currentDay, currentDayItem, item)">
                  <i class="fa fa-arrow-right"></i>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <template v-slot:modal-footer>
          <b-button type="reset" @click="onReset" variant="danger"
          >{{
              $t("common.close")
            }}
          </b-button>
        </template>
      </b-modal>
      
      <b-modal id="copyModal" ref="copy-modal">
        <template v-slot:modal-title>
          Скопировать на неделю
        </template>
        <div class="row" style="margin-bottom: 10px">
          <div class="col-lg-12">
            <b-form-datepicker
                id="copy-datepicker"
                v-model="copyDate"
                class="mb-2"
                locale="ru"
                start-weekday="1"
                :date-disabled-fn="dateDisabled"></b-form-datepicker>
          </div>
        </div>
        
        <template v-slot:modal-footer>
          <b-button type="submit" @click="copyToWeek" variant="primary"
                    :class="{'kt-spinner kt-spinner--light disabled':loading }"
                    :disabled="loading"
          >
            Скопировать
          </b-button>
          <b-button type="reset" @click="closeCopyModal" variant="danger">{{
              $t("common.close")
            }}
          </b-button>
        </template>
      </b-modal>
      <b-modal id="moveItem" ref="moveItem-modal" @hide="forceReDraw">
        <template v-slot:modal-title>
          {{ $t("common.move") }}
        </template>
        <div style="height: 400px; overflow-y: auto">
          <b-form>
            <b-form-group id="input-group-5" label-for="input-5">
              <template v-slot:label>
                {{ $t("timeTable.moveDay") }}
              </template>
              <b-form-select
                  id="input-2"
                  v-model="moveItem.day"
                  :options="dayOptions"
                  required
              ></b-form-select>
            </b-form-group>
            <b-form-group id="input-group-5" label-for="input-5">
              <template v-slot:label>
                {{ $t("timeTable.moveDayItem") }}
              </template>
              <b-form-select
                  id="input-2"
                  v-model="moveItem.dayItem"
                  :options="dayItemOptions"
                  required
              ></b-form-select>
            </b-form-group>
          </b-form>
        </div>

        <template v-slot:modal-footer>
          <b-button type="reset" @click="moveButtonPressed" variant="success"
          >{{
              $t("common.move")
            }}
          </b-button>          <b-button type="reset" @click="closeMoveModal" variant="danger"
        >{{
            $t("common.close")
          }}
        </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<style>
.column-date{
  font-weight: bold;
  white-space: pre;
}

#submitButton {
  margin-top: 10px;
}

/*table, th, td {
  border: 1px solid black;
}*/

.additional-lesson{
  border: 3px solid red;
}

.time-table-item {
  width: 94%;
  background: #1BC5BD;
  color: #FFFFFF;
  margin: 4px;
  padding: 15px 5px;
  cursor: pointer;
}

.group-name {
  line-height: 35px;
  background: #84D49E;
  color: #FFFFFF;
  padding: 10px 15px;
  margin: 15px 0 0 0;
  position: relative;
  
  text-align: right;
  min-height: 45px;
}

.group-name>span{
  position: absolute;
  left: 10px;
}

.group-name > button {
  margin-right: 10px;
}
</style>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import DictionariesService from "@/common/dictionaries.service";
export default {
  name: "timeTableForSchool",
  components: {
    KTPortlet
  },
  data() {
    return {
      filter: {
        educationYear: DictionariesService.currentYear(),
        level: 1,
        shift: 1,
        date: DictionariesService.startOfWeek(new Date()),
      },
      vv: [],
      data: [],
      groups: [],
      groupsIds: [],
      groupTimeTableItems: [],
      currentDay: 1,
      currentDayItem: 1,
      currentGroup: 0,
      dayItems: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      days: [1, 2, 3, 4, 5, 6],
      form: {},
      subjects: [],
      subjectNames: {},
      groupId: null,
      groupInfo: {},
      levels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      educationYears: DictionariesService.educationYearOptions(),
      shifts: [{text: "Первая", value: 1}, {text: "Вторая", value: 2}, { text: "Третья", value: 3 }],
      watch: {
        form: {
          handler: function () {
          },
          deep: true
        }
      },
      reDrawTable: 0,
      toDelete: [],
      copyDate:null,
      loading: true,
      dates: {},
      moveItem:{
        groupId:0,
        day:0,
        dayItem:0,
        index:0,
      },
      dayOptions:DictionariesService.getDayOptions(),
      dayItemOptions:DictionariesService.getDayItemOptions(),
      usedItems:{}
    };
  },
  mounted() {
    this.filterData();
  },
  methods: {
    dateDisabled(ymd, date) {
      const weekday = date.getDay();
      return weekday !== 1;
    },
    clickItem(item, day, dayItem, subjects, subjectsPerWeek) {
      this.$refs["createItem-modal"].show();
      this.currentDay = day;
      this.currentDayItem = dayItem;
      this.currentGroup = item.group.id;
      this.subjects = subjects;
      this.subjectsPerWeek = subjectsPerWeek;
    },
    createItem() {
      this.form[this.currentGroup][this.currentDay][this.currentDayItem].push({
        subjectId: 0,
        roomNumber: ''
      });
      this.reDrawTable = Math.random();
    },
    removeItem(group, day, dayItem, k) {
      let item = this.form[group][day][dayItem][k];
      if (!isNaN(item.id) && item.id > 0) {
        this.toDelete.push(item.id);
      }

      this.usedItems[group][item.subjectId] = this.usedItems[group][item.subjectId] - 1;
      
      this.form[group][day][dayItem].splice(k, 1);
      this.reDrawTable = Math.random();
    },
    /**/
    editItem(group, day, dayItem, k) {
      this.moveItem = {
        groupId:group,
        day:day,
        dayItem:dayItem,
        index:k,
        prevDay:day,
        prevDayItem:dayItem
      }
      this.$refs["moveItem-modal"].show();
    },

    pickItem(group, day, dayItem, item) {
      this.form[group][day][dayItem].push({
        subjectId: item.value,
        roomNumber: item.roomNumber,
        teacherName: item.teacherName,
      });

      this.usedItems[group][item.value] = this.usedItems[group][item.value] + 1;

      this.reDrawTable = Math.random();
    },
    getSubjectsPerWeek(){
      let ret = [];
      for (let i in this.subjectsPerWeek){
        let item = this.subjectsPerWeek[i];

        if(this.usedItems[this.currentGroup][item.value] < item.perWeekCount){
          ret.push(item)
        }
      }

      return ret;
    },
    dragStart(event){
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.setData('itemID',  event.target.id)
    },
    allowDrop:function(event) {
      event.preventDefault();
    },
    drop:function(event) {
      event.preventDefault();
      let id = event.dataTransfer.getData("itemID");
      let item = document.getElementById(id);
      let itemGroupId = item.getAttribute('group-id');
      let itemDay = item.getAttribute('day');
      let itemDayItem = item.getAttribute('day-item');
      let itemIndex = item.getAttribute('index');

      let target = event.target;

      let targetGroupId = target.getAttribute('group-id');
      let targetDay = target.getAttribute('day');
      let targetDayItem = target.getAttribute('day-item');

      this.itemMove(itemGroupId, itemDay, itemDayItem,
          itemIndex, targetGroupId, targetDay, targetDayItem);
    },
    moveButtonPressed(){
      this.$refs["moveItem-modal"].hide();

      this.itemMove(
          this.moveItem.groupId
          ,this.moveItem.prevDay
          ,this.moveItem.prevDayItem
          ,this.moveItem.index
          ,this.moveItem.groupId
          ,this.moveItem.day
          ,this.moveItem.dayItem
      );

      this.moveItem = {};
    },
    closeMoveModal(){

      this.$refs["moveItem-modal"].hide();
      this.moveItem = {};
    },

    itemMove(itemGroupId, itemDay, itemDayItem, itemIndex, targetGroupId, targetDay, targetDayItem){
      if(itemGroupId !== targetGroupId){
        return;
      }

      if(targetDay === itemDay && itemDayItem === targetDayItem ){
        return;
      }

      this.form[targetGroupId][targetDay][targetDayItem]
          .push(this.form[itemGroupId][itemDay][itemDayItem][itemIndex]);

      this.form[itemGroupId][itemDay][itemDayItem].splice(itemIndex, 1);

      this.reDrawTable = Math.random();
    },
    /**/
    filterData() {
      
      this.loading = true;
      let $this = this;
      ApiService.postSecured("timeTable/getForSchool", this.filter).then(({data}) => {
        console.log(data);
        $this.groupsIds = [];
        for (let i in data.list) {
          this.form[data.list[i].group.id] = {
            1: {},
            2: {},
            3: {},
            4: {},
            5: {},
            6: {}
          };
          
          $this.groupsIds.push(data.list[i].group.id);

          this.usedItems[data.list[i].group.id] = {};
          
          for (let ii in this.form[data.list[i].group.id]) {
            this.form[data.list[i].group.id][ii] = {};
            for (let iii in this.dayItems) {
              this.form[data.list[i].group.id][ii][this.dayItems[iii]] = []
            }
          }

          for (let subjectsIndex in data.list[i].subjects) {
            let item = data.list[i].subjects[subjectsIndex];
            this.subjectNames[item.value] = item.text;

            this.usedItems[data.list[i].group.id][item.value] = 0;
          }
          
          for (let lessonIndex in data.list[i].lessons) {
            let lesson = data.list[i].lessons[lessonIndex];
            this.form[data.list[i].group.id][lesson.dayOfWeek][lesson.lessonNumber].push({
              subjectId: lesson.subjectId,
              roomNumber: lesson.roomNumber,
              teacherName: lesson.teacherName,
              id: lesson.id,
              isAdditional: lesson.isAdditional
            });

            if(lesson.isAdditional !== true){
              this.usedItems[data.list[i].group.id][lesson.subjectId] =
                  this.usedItems[data.list[i].group.id][lesson.subjectId] + 1;
            }            
          }


        }

        this.data = data.list;
        this.dates = data.dates;
        
        $this.loading = false;
      }).catch(() => {
        //console.log(response);
      });
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      //this.form = {};
      this.$refs["createItem-modal"].hide();
    },
    forceReDraw() {
      this.reDrawTable = Math.random();
    },
    onSubmit() {
      this.loading = true;
      
      let date = this.filter.date;
      let toSave = [];
      for (let ggroupIndex in this.form) {
        let groupData = this.form[ggroupIndex];
        for (let dayIndex in groupData) {
          let dayData = groupData[dayIndex];
          for (let slotIndex in dayData) {
            let slotData = dayData[slotIndex];
            if (slotData.length > 0) {
              let item = {
                dayOfWeek: parseInt(dayIndex),
                LessonNumber: parseInt(slotIndex),
                date: date,
                subjectIds: slotData
              };

              toSave.push(item)
            }
          }
        }
      }

      let data = {
        toSave: toSave,
        toDelete: this.toDelete,
      }

      let $this = this;
      ApiService.postSecured("timeTable/save", data)
          .then(function () {
            alert("Данные сохранены");
            
            
            $this.loading = false;
            
            $this.filterData();
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    reloadData() {
    },
    changeDate() {

      this.reloadData()
    },
    copyFromPrevWeek(groupId) {
      this.loading = true;
      let data = {
        groupId:groupId,
        date:this.filter.date,
      }
      let $this = this;
      ApiService.postSecured("timeTable/copyTimeTableFromPrevWeek", data)
          .then(function () {
            $this.filterData();
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    copyFromNextWeek(groupId) {
      this.loading = true;
      let data = {
        groupId:groupId,
        date:this.filter.date,
      }
      let $this = this;
      ApiService.postSecured("timeTable/copyTimeTableFromNextWeek", data)
          .then(function () {
            $this.filterData();
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    showCopyButtonFromPrevWeek(groupId) {
      for (let day in this.days){
        for (let dayItem in this.dayItems){
          if(this.form[groupId][this.days[day]][this.dayItems[dayItem]].length > 0){
            return false; 
          }
        }
      }
      
      return true;
    },
    openCopyModal() {
      this.$refs["copy-modal"].show();
    },
    copyFromWeek() {
      
    },
    copyToWeek() {
      this.loading = true;
      let data = {
        groupIds:this.groupsIds, 
        date:this.filter.date,
        copyingWeekDate:this.copyDate,
      }
      let $this = this;
      ApiService.postSecured("timeTable/copyTimeTableToWeek", data)
          .then(function () {
            $this.$refs["copy-modal"].hide();
            alert("Данные сохранены");

            $this.loading = false;
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    closeCopyModal(evt) {
      evt.preventDefault();
      this.copyDate = null;
      this.$refs["copy-modal"].hide();
    },
  }
};
</script>
